/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import { Carousel } from 'react-responsive-carousel';
import IMG1 from './img/1.png';
import IMG3 from './img/3.png';
import IMG4 from './img/4.png';
import IMG5 from './img/5.png';
import IMG6 from './img/6.png';
import IMG7 from './img/7.png';
// import IMG8 from './img/8.png';


import Logo from './img/logo.png';

import { Pane, Dialog } from 'evergreen-ui'

import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Form, Button, Spinner, Figure } from 'react-bootstrap';
import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'
import Component from '@reactions/component'
let arr = []

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      color: false,
      colo2: false,
      get_color2: '',
      name: '',
      gov: '',
      city: '',
      phone: '',
      size: '',
      count: 1,
      s40: false,
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      more: false,
      counts: [],
      items: [],
      prcie: 12000,
      price_t: 18000,
      discount: 8000,
    }

    // let value = document.getElementById('countvalue').innerHTML
    // console.log(value);

  }

  get_slectedv2(id, size, cls, index) {
    console.log(id);
    let getClass = document.getElementsByClassName(`${`sizeC|${index}`}`);
    for (let index = 0; index < getClass.length; index++) {
      // getClass[index].style.display = "block";
      getClass[index].style.color = "#008dde";
      getClass[index].style.background = " #fff";
      getClass[index].style.border = "3px solid #008dde";
    }
    let get_slected = document.getElementById(id)
    get_slected.style.color = "#fff";
    get_slected.style.background = " #008dde";
    let obj = {
      id: index,
      size: size,
      color: ''
    }

    let ind = arr.find(e => e.id === index)
    if (ind) {

      arr.find(e => e.id === index).size = size;
      // console.log('newArr', arr.find(e => e.id == index));

      //arr.findIndex((obj => obj.id == index))
      // arr = arr.find(e => e.id == index)
      // arr[0].size=size;
      // arr = arr.filter(e => e.id != index)
      //  arr.push(obj)

      // this.setState({ items: found })
    } else {
      arr.push(obj)

      // this.setState({ items: found })
    }



  }
  select_size(id, size) {
    let getClass = document.getElementsByClassName(`'testc'`);
    console.log(getClass);
    for (let index = 0; index < getClass.length; index++) {
      // getClass[index].style.display = "block";
      getClass[index].style.color = "#008dde";
      getClass[index].style.background = " #fff";
      getClass[index].style.border = "3px solid #008dde";
    }
    let get_slected = document.getElementById(id)
    get_slected.style.color = "#fff";
    get_slected.style.background = " #008dde";
    this.setState({ size: size })
  }
  componentDidMount() {

    this.more('1')






  }
  submit() {

    document.getElementById('spinnerdiv').style.display = 'flex'
    document.getElementById('submitbtn').style.display = "none"

    // for (let index = 0; index < this.state.counts.length; index++) {
    //   let get_slected = document.getElementById(id)
    let name = document.getElementById('name').value;
    let phone = document.getElementById('phone').value;
    let city = document.getElementById('city').value;
    let gov = document.getElementById('gov').value;
    let notes = document.getElementById('notes').value;

    let items = []
    // }

    // let name = document.getElementById('name').value;
    // let phone = document.getElementById('phone').value;
    // let city = document.getElementById('city').value;
    // let gov = document.getElementById('gov').value;
    // let size = this.state.size
    // let color = this.state.get_color2

    // if (!name) {
    //   window.alert('يرجى ادخال الاسم')

    //   document.getElementById('spinnerdiv').style.display = 'none'
    //   document.getElementById('submitbtn').style.display = "flex"

    //   return -1;


    // }
    // if (this.check_phone(phone)) {

    //   document.getElementById('spinnerdiv').style.display = 'none'
    //   document.getElementById('submitbtn').style.display = "flex"
    //   return -1;
    // }
    // if (!gov || gov === "none") {
    //   window.alert('يرجى ادخال المحافظة')
    //   document.getElementById('spinnerdiv').style.display = 'none'
    //   document.getElementById('submitbtn').style.display = "flex"
    //   return -1;
    // }
    // if (!city) {
    //   window.alert('يرجى ادخال المنطقة')
    //   document.getElementById('spinnerdiv').style.display = 'none'
    //   document.getElementById('submitbtn').style.display = "flex"
    //   return -1;
    // }
    let tprice = 5;
    if (arr.length > 0) {
      for (let index = 0; index < arr.length; index++) {
        if (arr[index].size === "" || arr[index].color === "") {
          window.alert('الرجاء التأكد من اختيار الون او القياس للمنتج')
          document.getElementById('spinnerdiv').style.display = 'none'
          document.getElementById('submitbtn').style.display = "flex"
          return -1;
        } else {

          let name = arr[index].color
          let price = arr[index].size
          let obj = {
            name: name,
            count: 1
          }
          let check = items.find(e => e.name === name)
          if (check) {
            items.find(e => e.name === name).count = items.find(e => e.name === name).count + 1;
            
          } else {
            items.push(obj)
          }
        }

      }
    } else if (arr.length === 0) {
      window.alert('الرجاء التأكد من اختيار الون او القياس للمنتج')
      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }
    let price = this.state.price_t
    let notes2 = ``;

    // for (let index = 0; index < items.length; index++) {
    //   console.log(items[index].name);
    //   if (notes2.length === 0) {

    //     notes2 = ` (${items[index].count}  *  ${items[index].name}) `

    //   } else {

    //     notes2 = `${notes2} /  (${items[index].count}  *  ${items[index].name})`

    //   }

    // }
    // axios.post('https://api.lamha-iq.com/users/web/order/v2', {
    //   name: name,
    //   gov: gov,
    //   city: city,
    //   phone: phone,
    //   page: 14,
    //   price: price / 1000,
    //   notes: notes,
    //   items: items,
    //   notes2
    // })
    //   .then(function (response) {
    //     window.location.href = "/done";
    //   })
    //   .catch(function (error) {
    //     document.getElementById('spinnerdiv').style.display = 'none'
    //     document.getElementById('submitbtn').style.display = "flex"
    //     console.log(error);
    //   });
  }
  check_phone(phone) {
    if (phone.length !== 11) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    let result = phone.substring(0, 4);

    if (result[[0]] != 0) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[[1]] != 7) {

      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[2] != 7 && result[2] != 8 && result[2] != 9 && result[2] != 5) {

      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[[3]] > 6) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }




    return false
  }
  set_coler(value, index, id) {
    let blid = document.getElementById(`colorb${index}`);
    let wlid = document.getElementById(`colorw${index}`);
    let bwlid = document.getElementById(`colorbw${index}`);
    // let ywlid = document.getElementById(`colory${index}`);

    let rwlid = document.getElementById(`colorr${index}`);
    let pwlid = document.getElementById(`colorp${index}`);

    let Mlid = document.getElementById(`colorM${index}`);
    let Nlid = document.getElementById(`colorN${index}`);

    Mlid.style.border = " 1px solid #000";
    Nlid.style.border = " 1px solid #000";

    blid.style.border = " 1px solid #000";
    wlid.style.border = " 1px solid #000";
    //  ywlid.style.border = " 1px solid #000";
    bwlid.style.border = " 1px solid #000";

    rwlid.style.border = " 1px solid #000";
    pwlid.style.border = " 1px solid #000";

    if (value === 'N') {
      Nlid.style.border = " 4px solid #16b60c";

    }

    if (value === 'M') {
      Mlid.style.border = " 4px solid #16b60c";

    }
    if (value === 'p') {
      pwlid.style.border = " 4px solid #16b60c";

    }
    if (value === 'r') {
      rwlid.style.border = " 4px solid #16b60c";

    }

    // if (value === 'y') {
    //   ywlid.style.border = " 4px solid #16b60c";

    // }
    if (value === 'b') {
      blid.style.border = " 4px solid #16b60c";

    }
    if (value === 'w') {
      wlid.style.border = " 4px solid #16b60c";

    }
    if (value === 'BL-WI') {
      bwlid.style.border = " 4px solid #16b60c";

    }

    // // wlid.style.border = "border: 1px solid #000"
    // console.log(blid);
    // if (value === 'b') {
    //   let getcolore = document.getElementsByClassName(`color${index}`)
    //   console.log(getcolore);
    //   for (let index = 0; index < getcolore.length; index++) {
    //     getcolore[index].style.border = "border: 1px solid #000"

    //   }
    //   let dd= document.getElementById(id)
    //   dd.style.border="5px solid #01dd77"
    //   // let cb = document.getElementById("bcolor")
    //   // let cw = document.getElementById("wcolor")
    //   // cb.className = "CheckColor1a"
    //   // cw.className = "CheckColor2"
    // }
    // if (value === 'w') {
    //   let getcolore = document.getElementsByClassName(`color${index}`)
    //   for (let index = 0; index < getcolore.length; index++) {
    //       // let dd= document.getElementById(`colorB${index}`)
    //     getcolore[index].style.border = "border: 1px solid #000"
    //   //  document.getElementById(`bcolorB${index}`).style.border = "border: 1px solid #000"

    //   }
    //   let dd= document.getElementById(id)
    //   console.log(dd);
    //   dd.style.border="5px solid #01dd77"
    // }


  }
  more(value) {
    var divs = []
    for (let index = 0; index < value; index++) {
      divs.push(
        <Component initialState={{
          count: 0, className: `btmn|${index}`, c: false, a: () => {


          }
        }} >
          {({ setState, state }) => (
            <div>
              <div key={index}>
                <div className="attr-detail attr-color mb-15" style={{ height: 225, disply: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <h4 style={{ paddingRight: 20, marginBottom: 10 }} className="mr-10">اللون</h4>
                  <ul className="list-filter color-filter" style={{ marginRight: 45 }}>

                    {/* 1 */}
                    <li>
                      {/* colorb */}
                      <Figure >
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="171x180"
                          src={IMG1}
                          className={`CheckColor1 ${`color${index}`}`} id={`colorb${index}`} onClick={(e) => {
                            let ind = arr.find(e => e.id === index)
                            if (ind) {
                              arr.find(e => e.id === index).color = "لوحة 1";
                              arr.find(e => e.id === index).size = 40;

                            } else {
                              let obj = {
                                id: index,
                                size: 40,
                                color: 'لوحة 1'
                              }
                              arr.push(obj)
                            }
                            this.set_coler('b', index, e.target.id)
                            //  this.setState({ color1: true, color2: false, get_color2: "WI" })

                          }} />
                      </Figure >



                    </li>

                    {/* 3 */}
                    <li>
                      {/* colorr */}
                      <Figure >
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="171x180"
                          src={IMG3}
                          className={`CheckColorR ${`color${index}`}`} id={`colorr${index}`} onClick={(e) => {

                            let ind = arr.find(e => e.id === index)
                            if (ind) {
                              arr.find(e => e.id === index).color = "لوحة 3";
                              arr.find(e => e.id === index).size = 40;

                            } else {
                              let obj = {
                                id: index,
                                size: 40,
                                color: 'لوحة 3'
                              }
                              arr.push(obj)
                            }
                            this.set_coler('r', index, e.target.id)
                            //  this.setState({ color1: true, color2: false, get_color2: "WI" })

                          }}


                        />
                      </Figure >
                    </li>

                    {/* 4 */}
                    <li>
                      {/* colorw */}
                      <Figure >
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="171x180"
                          src={IMG4}
                          className={`CheckColor2 ${`color${index}`}`} id={`colorw${index}`} onClick={(e) => {

                            let ind = arr.find(e => e.id === index)
                            if (ind) {
                              arr.find(e => e.id === index).color = "لوحة 4";

                            } else {
                              let obj = {
                                id: index,
                                size: '',
                                color: 'لوحة 4'
                              }
                              arr.push(obj)
                            }
                            this.set_coler('w', index, e.target.id)
                            //    this.setState({ color1: false, color2: true, get_color2: "WI" })

                          }}


                        />
                      </Figure >

                    </li>
                    {/* 5 */}
                    <li>
                      {/* pwlid */}
                      <Figure >
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="171x180"
                          src={IMG5}
                          className={`CheckColor2 ${`color${index}`}`} id={`colorp${index}`} onClick={(e) => {

                            let ind = arr.find(e => e.id === index)
                            if (ind) {
                              arr.find(e => e.id === index).color = "لوحة 5";
                              arr.find(e => e.id === index).size = 60;

                            } else {
                              let obj = {
                                id: index,
                                size: 60,
                                color: 'لوحة 5'
                              }
                              arr.push(obj)
                            }
                            this.set_coler('p', index, e.target.id)
                            //    this.setState({ color1: false, color2: true, get_color2: "WI" })

                          }}


                        />
                      </Figure >

                    </li>

                    {/* 6 */}
                    <li>
                      {/* pwlid */}
                      <Figure >
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="171x180"
                          src={IMG6}
                          className={`CheckColor2 ${`color${index}`}`} id={`colorbw${index}`} onClick={(e) => {

                            let ind = arr.find(e => e.id === index)
                            if (ind) {
                              arr.find(e => e.id === index).color = "لوحة 6";
                              arr.find(e => e.id === index).size = 35;

                            } else {
                              let obj = {
                                id: index,
                                size: 35,
                                color: 'لوحة 6'
                              }
                              arr.push(obj)
                            }
                            this.set_coler('BL-WI', index, e.target.id)
                            //    this.setState({ color1: false, color2: true, get_color2: "WI" })

                          }}


                        />
                      </Figure >

                    </li>

                    {/* 7 */}
                    <li>
                      {/* pwlid */}
                      <Figure >
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="171x180"
                          src={IMG7}
                          className={`CheckColorM ${`color${index}`}`} id={`colorM${index}`} onClick={(e) => {

                            let ind = arr.find(e => e.id === index)
                            if (ind) {
                              arr.find(e => e.id === index).color = "لوحة 7";
                              arr.find(e => e.id === index).size = 50;

                            } else {
                              let obj = {
                                id: index,
                                size: 50,
                                color: 'لوحة 7'
                              }
                              arr.push(obj)
                            }
                            this.set_coler('M', index, e.target.id)
                            //    this.setState({ color1: false, color2: true, get_color2: "WI" })

                          }}


                        />
                      </Figure >

                    </li>
                    {/* 8 */}
                    <li>
                      {/* pwlid */}
                      <Figure >
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="171x180"
                          src={'IMG8'}
                          className={`CheckColorN ${`color${index}`}`} id={`colorN${index}`} onClick={(e) => {

                            let ind = arr.find(e => e.id === index)
                            if (ind) {
                              arr.find(e => e.id === index).color = "لوحة 8";
                              arr.find(e => e.id === index).size = 50;

                            } else {
                              let obj = {
                                id: index,
                                size: 50,
                                color: 'لوحة 8'
                              }
                              arr.push(obj)
                            }
                            this.set_coler('N', index, e.target.id)
                            //    this.setState({ color1: false, color2: true, get_color2: "WI" })

                          }}


                        />
                      </Figure >

                    </li>
                  </ul>
                </div>

              </div>
              <hr />
            </div>
          )}
        </Component>

      )
    }
    this.setState({ counts: divs })

  }

  render() {
    return (
      <div className="App">

        <main className="main">
          <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>
            <center>
              <img width={75} height={75} src={Logo} alt="" />
              <h3>Liger.fashion</h3>
            </center>

          </div>
          <section className="mt-60 mb-60">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-detail accordion-detail">
                    <div className="row mb-50">
                      <div className="col-md-6 col-sm-12 col-xs-12">

                        <Carousel autoPlay infiniteLoop >
                          <div>
                            <img src={IMG1} alt='im' />
                          </div>

                          <div>
                            <img src={IMG3} alt='im' />
                          </div>
                          <div>
                            <img src={IMG4} alt='im' />
                          </div>
                          <div>
                            <img src={IMG5} alt='im' />
                          </div>

                          <div>
                            <img src={IMG6} alt='im' />
                          </div>

                          <div>
                            <img src={IMG7} alt='im' />
                          </div>

                          {/* <div>
                            <img src={IMG8} alt='im' />
                          </div> */}

                        </Carousel>
                        <div className="single-social-share clearfix mt-50 mb-15">
                          <div className="mobile-social-icon wow fadeIn  mb-sm-5 mb-md-0 animated">
                            {/* <a className="facebook" href="https://www.facebook.com/ligerfashionfootwear"><i className="fab fa-facebook-f"></i></a> */}

                           {/* // <a className="instagram" href="https://www.instagram.com/Liger.fashion/"><i className="fab fa-instagram"></i></a> */}
                          </div>
                        </div>

                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12" style={{ textAlign: "right" }} >
                        <div className="detail-info" >
                          <h2 className="title-detail">تراك رجالي فول ليكرا </h2>
                          <div style={{ width: '100%', disply: 'flex', justfyContent: 'flex-end' }}>
                            <div className="product-detail-rating" >

                              <div className="product-rate-cover text-end">
                                <div className="product-rate d-inline-block">
                                  <div className="product-rating" style={{ width: '90%' }}>
                                  </div>
                                </div>
                                <span className="font-small ml-5 text-muted"> (200k reviews)</span>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix product-price-cover">
                            <div className="product-price primary-color float-left">
                              <ins><span className="text-brand">دينار عراقي 12,000 </span></ins>
                              <ins><span className="old-price font-md ml-15">دينار عراقي 23,000 </span></ins>
                              <div className="save-price  font-md color3 ml-15" style={{ color: 'red', fontSize: '25px', marginTop: '20px' }}>55% خصم</div>
                            </div>
                          </div>
                          <div className="bt-1 border-color-1 mt-15 mb-15"></div>
                          <div className="short-desc mb-30">
                            <p style={{ fontSize: 18 }}>
                              😍 ملابس تركية 🇹🇷  درجة أولى 💯 بـ أسعار منافسه وأجود نوعية
                              <br></br>
                              ✔️ سعر التراك 12 الف فقط  ♦️
                              <br></br>
                              😍 ️القماش ليكرا  ويكون بارد وماص للتعرق بالاضافة الى تصاميم وألوان أنيقة تجعلك تبدو أكثر أناقة وجمال ♦️
                              <br></br>
                              القياسات (M L XL 2XL 3XL 4XL) ♦️
                              <br></br>
                              ✔✔️الفحص قبل الاستلام واذا كان مخالف للموصفات يتم ارجاعه بيد المندوب وبدون دفع اي أجور ♦️
                              <br></br>
                              ✔️ا لتوصيل 5 الاف لكافة المحافظات ️♦️
                              <br></br>
                              🚸 للطلب يرجى ملئ المعلومات اسفل ❤️
                              <br></br>
                              ❤️🌷❤️ اهلا و سهلا بكم
                            </p>
                          </div>

                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="الاسم" id="name" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الاسم  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            {/* <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="المحافظة" id="gov" /> */}

                            <Form.Select id="gov" style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} >
                              <option value="none">اختر محافظة</option>
                              <option value="بغداد">بغداد</option>
                              <option value="بابل">بابل</option>
                              <option value="الأنبار">الأنبار</option>
                              <option value="البصرة">البصرة</option>
                              <option value="دهوك">دهوك</option>
                              <option value="القادسية">القادسية</option>
                              <option value="ديالى">ديالى</option>
                              <option value="ذي قار">ذي قار</option>
                              <option value="السليمانية">السليمانية</option>
                              <option value="صلاح الدين">صلاح الدين</option>
                              <option value="كركوك">كركوك</option>
                              <option value="كربلاء">كربلاء</option>
                              <option value="المثنى">المثنى</option>
                              <option value="النجف">النجف</option>
                              <option value="نينوى">نينوى</option>
                              <option value="واسط">واسط</option>
                              <option value="ميسان">ميسان</option>
                              <option value="اربيل">اربيل</option>
                              <option value="موصل">موصل</option>
                            </Form.Select>
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المحافظة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="المدينة" id="city" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المدينة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"number"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="الهاتف" id="phone" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الهاتف  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="ملاحظات" id="notes" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>ملاحظات</h5>

                          </div>
                          <div className="attr-detail attr-size" style={{ height: 100, disply: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                            <h5 className="mr-10" style={{ textAlign: 'center', padding: 10 }}>عدد القطع</h5>
                            <ul className="list-filter size-filter font-small" style={{ marginRight: 40 }}>
                              <li>
                                <div className={'decDiv'}
                                  id="decDiv"
                                  onClick={(e) => {
                                    let value = document.getElementById('countvalue').innerHTML
                                    if (Number(value) > 1) {
                                      let newnew = Number(value) - 1
                                      document.getElementById('countvalue').innerHTML = newnew

                                      if (arr.length > 1) {
                                        arr.length = arr.length - 1

                                      }
                                      let price = (this.state.prcie * newnew) + 5000
                                      console.log(price);
                                      this.setState({ price_t: price })
                                      this.more(newnew)
                                    }
                                  }}

                                >-</div>
                              </li>
                              <li>
                                <p className={"count"} id="countvalue">1</p></li>
                              <li><div className={"incDiv"}
                                id="incDiv"
                                onClick={(e) => {
                                  this.setState({ more: true })
                                  let value = document.getElementById('countvalue').innerHTML
                                  let newvalue = Number(value) + 1
                                  document.getElementById('countvalue').innerHTML = newvalue
                                  let price = (this.state.prcie * newvalue) + 5000;
                                  let discount = newvalue * 8000

                                  this.setState({ price_t: price, discount: discount + 8000 })
                                  this.more(newvalue)

                                }}
                              >+</div></li>


                            </ul>
                          </div>

                          {
                            this.state.counts
                          }


                          <div className="bt-1 border-color-1 mt-30 mb-30"></div>
                          <div>
                            <div className="attr-detail attr-size" style={{ disply: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>


                              <div style={{ marginTop: 10, marginBottom: 10, width: '100%', height: 1, background: '#d4d7dd' }}></div>

                              <h5 className="mr-10" style={{ textAlign: 'center', padding: 10, fontSize: 25 }}>: مجموع الحساب بعد الخصم الكلي مع التوصيل </h5>


                              <h5 className="mr-10" style={{ textAlign: 'center', padding: 10, direction: 'rtl', fontSize: 25 }}> IQD <span>{this.state.price_t}</span> <span style={{ textDecoration: 'line-through', color: 'red' }} >IQD {this.state.price_t + this.state.discount}</span>  </h5>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>

                                <div style={{ display: 'flex', justifyContent: 'center' }}>

                                  <div id="spinnerdiv" style={{ display: 'none' }}>
                                    <Button variant="primary" style={{ borderRadius: '35px' }} disabled>
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                      ...  تحميل
                                    </Button>
                                  </div>

                                  {/* <button type="submit" id="submitbtn" className="button button-add-to-cart"
                              onClick={() => {
                                this.submit()
                                // console.log(this.state.size, this.state.get_color2);
                              }}
                            >احجز الان</button> */}

                                  <Component initialState={{ isShown: false, isShown: false }}>
                                    {({ setState, state }) => (
                                      <Pane>
                                        <Dialog
                                          isShown={state.isShown}
                                          title="تأكيد"
                                          onCloseComplete={() => setState({ isShown: false })}
                                          confirmLabel="احجز الان"
                                          onConfirm={() => {
                                            this.submit()
                                            setState({ isShown: false })
                                          }}
                                          cancelLabel="رجوع"
                                        >
                                          <p style={{ textAlign: 'end' }}>
                                            ❗❗ قبل الحجز ياريت
                                            <br></br>
                                            👱‍♂️📦 لو تعرف انه اكو شخص رح يجهز الك هذا الطلب
                                            <br></br>
                                            🚗👴 واكو سايق رح يوصل الطلب للعنوان اللي مثبته
                                            <br></br>
                                            😥😭🙏 كل هذا جهد تعب ومصاريف والموضوع مو لعبة وخلي الله بين عيونك
                                            <br></br>
                                            ✔👍💪✔  وتأكد من نفسك قبل ما تحجز انه رح تستلم الطلب ومثبت المعلومات الصحيحة
                                            <br></br>
                                            ❤😍🥰🤩 واذا متأكد من كل شي شكرا الك لان حجز من خلالنا ونتعهد الك رح يوصلك الطلب باحسن صورة
                                            <br></br>
                                            🙏🌷🌷 شكرالك
                                          </p>                                  </Dialog>
                                        <button type="submit" id="submitbtn" className="button button-add-to-cart"
                                          onClick={() => {
                                            setState({ isShown: true })
                                            //    this.submit()
                                            // console.log(this.state.size, this.state.get_color2);
                                          }}
                                        >احجز الان</button>

                                      </Pane>
                                    )}
                                  </Component>,
                                </div>

                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Home;
